:root {
	--app-height: 100%;
}

@font-face {
	font-family: 'Calibre';
	src: local('Calibre Light'), local('Calibre-Light'),
			url('./fonts/Calibre-Light/Calibre-Light.woff2') format('woff2'),
			url('./fonts/Calibre-Light/Calibre-Light.woff') format('woff'),
			url('./fonts/Calibre-Light/Calibre-Light.ttf') format('truetype');
			font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'Calibre';
	src: local('Calibre Regular'), local('Calibre-Regular'),
			url('./fonts/Calibre-Regular/Calibre-Regular.woff2') format('woff2'),
			url('./fonts/Calibre-Regular/Calibre-Regular.woff') format('woff'),
			url('./fonts/Calibre-Regular/Calibre-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Calibre';
	src: local('Calibre Semi bold'), local('Calibre-Semi-bold'),
			url('./fonts/Calibre-Semibold/Calibre-Semibold.woff2') format('woff2'),
			url('./fonts/Calibre-Semibold/Calibre-Semibold.woff') format('woff'),
			url('./fonts/Calibre-Semibold/Calibre-Semibold.ttf') format('truetype');
		font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Calibre';
	src: local('Calibre Semi bold Italic'), local('Calibre-Semi-bold-Italic'),
			url('./fonts/Calibre-SemiboldItalic/Calibre-SemiboldItalic.woff2') format('woff2'),
			url('./fonts/Calibre-SemiboldItalic/Calibre-SemiboldItalic.woff') format('woff'),
			url('./fonts/Calibre-SemiboldItalic/Calibre-SemiboldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Calibre';
	src: local('Calibre Italic'), local('Calibre-Italic'),
			url('./fonts/Calibre-Italic/Calibre-Italic.woff2') format('woff2'),
			url('./fonts/Calibre-Italic/Calibre-Italic.woff') format('woff'),
			url('./fonts/Calibre-Italic/Calibre-Italic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}
@font-face {
	font-family: 'Calibre';
	src: local('Calibre Bold'), local('Calibre-Bold'),
			url('./fonts/Calibre-Bold/Calibre-Bold.woff2') format('woff2'),
			url('./fonts/Calibre-Bold/Calibre-Bold.woff') format('woff'),
			url('./fonts/Calibre-Bold/Calibre-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: 'Calibre';
	src: local('Calibre Black'), local('Calibre-Black'),
			url('./fonts/Calibre-Black/Calibre-Black.woff2') format('woff2'),
			url('./fonts/Calibre-Black/Calibre-Black.woff') format('woff'),
			url('./fonts/Calibre-Black/Calibre-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

.scrollblock {display: none;}
@media only screen and (max-width: 800px) {
	.scrollblock {
		display: block;;

		width:100%;
		height:125px;
		position: absolute;
		bottom:0;
	}

	canvas {
		touch-action: none;
		pointer-events: none;
	}
}

canvas {
	touch-action: auto;
	pointer-events: auto;
}

ul {
list-style-type: circle;
}

.toplist {	list-style-type:circle; text-align: center; }
.toplist li {
display: inline-block; margin-right: 10px; font-size: 30px; font-weight: 600; margin-bottom: 10px; line-height: 1.1em;
}
.toplist li::before { /* add the new bullet point */
	display: inline-block;
		content: '';
		-webkit-border-radius: 0.375rem;
		border-radius: 0.375rem;
		height: 0.4rem;
		width: 0.4rem;
		margin-bottom: 0.25rem;
		margin-right: 0.8rem;
		background-color: #000000;
}
.header {

	padding-top: 56.25%;
	background-position: 50%;
}
.video-background {
  width: 100vw;
  height: 100vh;
	background-color: #dccdcc;
}
.video-background iframe {
	
	background-size: cover;
	background-position: center center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  transform: translate(-50%, -50%);
}

@media (min-aspect-ratio: 16/10) {
  .video-background iframe {
    /* height = 100 * (9 / 16) = 56.25 */
    height: 64vw;
  }
}
@media (max-aspect-ratio: 16/10) {
  .video-background iframe {
    /* width = 100 / (9 / 16) = 177.777777 */
    width: 160.78vh;
  }
}

.video-back {
  background: url('./video-bg.png');
  background-position: 50%;
	background-size: cover;
}
.video {
  padding-top: 62.5%;
}
html {
	scroll-behavior: smooth;
}

.page-head{
	min-height: 550px;
}

html{scroll-behavior:auto}

.text-shadow {
	text-shadow: -2px 2px 0px #000000;;
}

@media (min-width: 768px) {
	.text-shadow {
		text-shadow: -3px 3px 0px #000000;;
	}
}

html,
body {
    width: 100vw;
    height: 100vh;
    height: var(--app-height);
}